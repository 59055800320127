const TimeFormatter = (timestamp, showDate = false) => {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  const options = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return `${
    showDate ? formattedDate : ""
  }  ${formattedHours}:${formattedMinutes} ${ampm}`;
};

export default TimeFormatter;
