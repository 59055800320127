import React, { useState } from "react";
import "./RightSideBar.css";
import ContactInfo from "../ContactInfo/ContactInfo";
import { useSelector } from "react-redux";

const RightSideBar = ({ open, onClose }) => {
  return (
    <section
      className={
        open ? "rightsidebar-container-show" : "rightsidebar-container-hide"
      }
    >
      <ContactInfo open={open} onClose={onClose} />
    </section>
  );
};

export default RightSideBar;
