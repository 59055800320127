import React, { useEffect, useRef, useState } from "react";
import "./Sidebar.css";
import { ReactComponent as ChatIcon } from "../../assets/images/chat.svg";
import { ReactComponent as MenuIcon } from "../../assets/images/menu.svg";
import sampleChatlist from "../../constants/sampleChatlist";
import ChatlistContainer from "../ChatlistContainer/ChatlistContainer";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Settings from "../Settings/Settings";
import NewChat from "../NewChat/NewChat";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileData, logoutUser } from "../../redux/slices/authSlice";
import {
  fetchChatList,
  setCanSendMessages,
  setSelectedChatId,
  setSelectedContactInfo,
  setSelectedName,
} from "../../redux/slices/chatSlice";
import TimeFormatter from "../../utils/TimeFormatter";
import { fetchChatMessages } from "../../redux/slices/chatMessageSlice";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import Loader from "../Loader/Loader";
import { Oval } from "react-loader-spinner";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarNewChatOpen, setSidebarNewChatOpen] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const chatList = useSelector((state) => state.chat.chatList);
  const [selectedChatItemId, setSelectedChatItemId] = useState(null);
  const [unread, setUnread] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const scrollableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [finalList, setFinalList] = useState([]);

  useEffect(() => {
    // Fetch initial chat list
    dispatch(fetchChatList({ token })).then((res) => {
      if (res.payload) {
      }
      if (res?.payload?.data) {
        setFinalList(res?.payload?.data);
      }
    });

    // Set up an interval to fetch chat list every 1 minute
    const chatListInterval = setInterval(() => {
      dispatch(fetchChatList({ token }));
    }, 30000); // 30000 milliseconds = 30 seconds

    // Clean up the interval when the component unmounts or the dependencies change
    return () => clearInterval(chatListInterval);
  }, [dispatch, token]);

  useEffect(() => {
    const fetchProfileDataInterval = setInterval(() => {
      dispatch(fetchProfileData());
    }, 30000); // 30000 milliseconds = 30 seconds

    return () => clearInterval(fetchProfileDataInterval);
  }, [dispatch]);

  //test logic
  // useEffect(() => {
  //   if (unread > 0) {
  //     dispatch(fetchChatMessages({ selectedChatItemId, token }));
  //     dispatch(fetchChatList(token));
  //     console.log("triggered from sidebar");
  //   }
  // }, [selectedChatItemId]);

  const openModal = () => {
    setIsModalOpen(true);
    handleClose();
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleChatItemClick = (
    itemId,
    contactInfo,
    name,
    unreadCount,
    canSendMessages
  ) => {
    dispatch(setSelectedChatId(itemId));
    dispatch(setSelectedContactInfo(contactInfo));
    dispatch(setSelectedName(name));
    setSelectedChatItemId(itemId);
    dispatch(setCanSendMessages(canSendMessages));
    setUnread(unreadCount);
  };

  const handleScroll = () => {
    const scrollableElement = scrollableRef.current;
    const isAtBottom =
      scrollableElement.scrollTop + scrollableElement.clientHeight >=
      scrollableElement.scrollHeight;

    if (isAtBottom) {
      // Fetch more messages when scrolling to the bottom
      setCurrentPage((prevPage) => prevPage + 1);
      setLoading(true);
      dispatch(fetchChatList({ token, page: currentPage + 1 })).then((res) => {
        if (res.payload) {
          setLoading(false);
        }
        if (res?.payload?.data?.length > 0) {
          // Calculate the desired scroll position (50% from the bottom)
          const scrollHeight = scrollableElement.scrollHeight;
          const desiredScrollPosition = scrollHeight * 0.5;
          setFinalList((prev) => [...prev, ...res?.payload?.data]);
          // Scroll to 50% from the bottom
          scrollableElement.scrollTop = desiredScrollPosition;
        }
      });
    }
  };

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleSidebarNewChatToggle = () => {
    setSidebarNewChatOpen(!sidebarNewChatOpen);
  };

  const handleSidebarClose = () => {
    setSidebarOpen(false);
  };

  const handleSidebarNewChatClose = () => {
    setSidebarNewChatOpen(false);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    handleClose();
  };
  const handleSettings = () => {
    handleSidebarToggle();
    handleClose();
  };
  return (
    <section>
      <div className="sidebar-header">
        <div className="sidebar-header-innerContainer">
          <p className="sidebar-header-name" onClick={handleSidebarToggle}>
            {user?.data?.name}
          </p>
          <div className="sidebar-menu-container">
            {/* <ChatIcon
              className="sidebar-chatIcon"
              onClick={handleSidebarNewChatToggle}
            /> */}
            <MenuIcon className="sidebar-menuIcon" onClick={handleClick} />

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleSettings}>Settings</MenuItem>
              <MenuItem onClick={openModal}>Logout</MenuItem>
            </Menu>
          </div>
        </div>
      </div>

      <div
        className="sidebar-chatlist-container"
        ref={scrollableRef}
        onScroll={handleScroll}
      >
        {loading && (
          <div className="sidebar-chatlist-spinner">
            <Oval
              visible={true}
              height="50"
              width="50"
              color="#4fa94d"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        )}

        {/* {chatList?.data?.map((item) => { */}
        {finalList?.map((item) => {
          const formattedTime = TimeFormatter(item.last_message_time, true);

          // Conditionally set unread count based on whether the item is selected
          const unreadCount = selectedChatItemId === item.id ? 0 : item.unread;
          return (
            <ChatlistContainer
              key={item.id}
              name={item.name}
              message={item.message}
              time={formattedTime}
              // unreadCount={item.unread}
              unreadCount={unreadCount}
              isSelected={selectedChatItemId === item.id}
              onClick={() =>
                handleChatItemClick(
                  item?.id,
                  item?.phone,
                  item?.name,
                  item?.unread,
                  item?.can_send_message
                )
              }
            />
          );
        })}
        {/* {loading && <Loader />} */}
      </div>
      <Settings open={sidebarOpen} onClose={handleSidebarClose} />
      <NewChat open={sidebarNewChatOpen} onClose={handleSidebarNewChatClose} />

      <ConfirmationModal
        text={"Are you are sure you want to log out?"}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        onYes={handleLogout}
        yesBtnText={"Yes, Log Out"}
      />
    </section>
  );
};

export default Sidebar;
