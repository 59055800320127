import React, { useEffect, useRef, useState } from "react";
import "./ChatBox.css";
import { ReactComponent as MenuIcon } from "../../assets/images/menu.svg";
import { ReactComponent as SmileIcon } from "../../assets/images/smile.svg";
import { ReactComponent as AttachIcon } from "../../assets/images/attachment.svg";
import { ReactComponent as DoubleTick } from "../../assets/images/double-tick.svg";
import { ReactComponent as PDF } from "../../assets/images/pdf.svg";
import { ReactComponent as TXT } from "../../assets/images/txt.svg";
import Swal from "sweetalert2";
import DescriptionIcon from "@mui/icons-material/Description";
import CollectionsIcon from "@mui/icons-material/Collections";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import VideocamIcon from "@mui/icons-material/Videocam";
import RightSideBar from "../RightSideBar/RightSideBar";
import DownloadIcon from "@mui/icons-material/Download";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import MessageInfo from "../MessageInfo/MessageInfo";
import ReelPlayer from "../ReelPlayer/ReelPlayer";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../redux/slices/sidebarSlice";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import sampleChatContent from "../../constants/sampleChatContent";
import TimeFormatter from "../../utils/TimeFormatter";
import { fetchChatMessages } from "../../redux/slices/chatMessageSlice";
import TextLinkFormatter from "../../utils/TextLinkFormatter";
import Loader from "../../components/Loader/Loader";
import { ThreeDots } from "react-loader-spinner";
import FileDownloader from "../../utils/FileDownloader";
import ImageModal from "../ImageModal/ImageModal";
import { sendMessage } from "../../redux/slices/messageSlice";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AttachmentDetailModal from "../AttachmentDetailModal/AttachmentDetailModal";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ErrorIcon from "@mui/icons-material/Error";
import {
  setSelectedAttachmentFileName,
  setSelectedAttachmentUrl,
  setSelectedImagePreview,
  setSelectedMessageInfo,
} from "../../redux/slices/chatSlice";
import { logoutUser } from "../../redux/slices/authSlice";

const ChatBox = () => {
  const [inputValue, setInputValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null); //for attachements
  const [anchorE3, setAnchorE3] = useState(null); //for message drop down

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [messageInfoOpen, setMessageInfoOpen] = useState(false);
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.mobile.isMobile);
  const [showButton, setShowButton] = useState(false);
  const scrollableRef = useRef(null);
  const token = useSelector((state) => state.auth.token);
  const chatId = useSelector((state) => state.chat.selectedChatId);
  const chatMessages = useSelector((state) => state.chatMessage.chatMessages);
  const contactName = useSelector((state) => state.chat.selectedName);
  const [currentPage, setCurrentPage] = useState(0);
  const [allMessages, setAllMessages] = useState([]);
  const [finalMessages, setFinalMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [attachmentLoader, setAttachmentLoader] = useState(false);
  const [isAtTop, setIsAtTop] = useState(false);
  // console.log("fetched messages", finalMessages);
  const contactInfo = useSelector((state) => state.chat.selectedContactInfo); //selected phone number
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [base64Only, setBase64Only] = useState([]);
  const [attachmentFileName, setAttachmentFileName] = useState([]);
  const [messageType, setMessageType] = useState([]); //for types other than text
  const [fileSize, setFileSize] = useState([]);
  const canSendMessages = useSelector((state) => state.chat.canSendMessages);
  const attachedFileName = useSelector(
    (state) => state.chat.selectedAttachmentFileName
  );
  const attachedUrl = useSelector((state) => state.chat.selectedAttachmentUrl);

  const handleDownload = () => {
    FileDownloader(attachedUrl, attachedFileName);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;

    // Process the selected files
    processFiles(files);
  };
  const processFiles = (files) => {
    const promises = Array.from(files).map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          const base64Data = event.target.result;
          resolve({ file, base64Data });
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    });

    Promise.all(promises)
      .then((results) => {
        openAttachmentModal();
        setSelectedFiles(results);
        setAttachmentFileName(results[0]?.file?.name);
        setFileSize(results[0]?.file?.size);
        const type = results[0]?.file?.type.split("/")[0];
        // console.log(type);
        if (type === "application" || type === "text") {
          setMessageType("document");
        } else {
          setMessageType(type);
        }
        // Handle the results (each object contains the file and its base64 data)
        const regex = /base64,(.*)/;
        const match = results[0]?.base64Data?.match(regex);
        if (match && match[1]) {
          const trimmedContent = match[1];
          setBase64Only(trimmedContent);
        } else {
          console.log("Invalid base64 content");
        }
      })
      .catch((error) => {
        console.error("Error reading files:", error);
      });
  };

  const openModal = (image) => {
    setIsModalOpen(true);
    dispatch(setSelectedImagePreview(image));
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openAttachmentModal = () => {
    setIsAttachmentModalOpen(true);
  };

  const closeAttachmentModal = () => {
    setIsAttachmentModalOpen(false);
  };

  const openDownloadConfirmationModal = (url, fileName) => {
    dispatch(setSelectedAttachmentFileName(fileName));
    dispatch(setSelectedAttachmentUrl(url));
    setIsDownloadModalOpen(true);
  };

  const closeDownloadConfirmationModal = () => {
    setIsDownloadModalOpen(false);
  };

  useEffect(() => {
    // Fetch initial messages
    setLoading(true);
    dispatch(fetchChatMessages({ chatId, token })).then((res) => {
      if (res.payload) {
        setLoading(false);
      }
      if (res?.payload?.status === 200) {
        setFinalMessages(res?.payload?.data);
      } else if (res?.payload?.status === 302) {
        Swal.fire({
          title: "Session Expired!",
          text: "Your session has expired. Please login again.",
          icon: "warning",
          confirmButtonText: "Ok",
        });
        dispatch(logoutUser());

        //session has expired
        // dispatch logut
      }
    });

    // Set up an interval to fetch messages every 1 minute
    const messagesInterval = setInterval(() => {
      dispatch(fetchChatMessages({ chatId, token }));
    }, 30000); // 30000 milliseconds = 30 seconds

    // Clean up the interval when the component unmounts or the dependencies change
    return () => clearInterval(messagesInterval);
  }, [dispatch, chatId, token]);

  useEffect(() => {
    setCurrentPage(0);
    scrollToBottom();
  }, [chatId]);

  const handleScroll = () => {
    const scrollableElement = scrollableRef.current;
    const isAtTop = scrollableElement.scrollTop === 0;

    if (isAtTop) {
      // Fetch more messages when scrolling to the top
      setCurrentPage((prevPage) => prevPage + 1);
      setLoading(true);
      dispatch(
        fetchChatMessages({ chatId, token, page: currentPage + 1 })
      ).then((res) => {
        if (res.payload) {
          setLoading(false);
        }
        if (res?.payload?.data?.length > 0) {
          // Calculate the desired scroll position (50% from the bottom)
          const scrollHeight = scrollableElement.scrollHeight;
          const desiredScrollPosition = scrollHeight * 0.5;
          setIsAtTop(true);
          setFinalMessages((prev) => [...prev, ...res?.payload?.data]);
          // Scroll to 50% from the bottom
          scrollableElement.scrollTop = desiredScrollPosition;
        }
      });
    }

    // Check if the user is at the bottom to show/hide the scroll button
    const isAtBottom =
      scrollableElement.scrollTop + scrollableElement.clientHeight ===
      scrollableElement.scrollHeight;
    setShowButton(!isAtBottom);
  };

  const scrollToBottom = () => {
    if (scrollableRef.current) {
      const scrollHeight = scrollableRef.current.scrollHeight;
      scrollableRef.current.scrollTop = scrollHeight;
    }
    setShowButton(false);
  };

  useEffect(() => {
    // Scroll to the bottom after rendering the messages
    if (isAtTop === false) {
      scrollToBottom();
    }
  }, [finalMessages]);

  useEffect(() => {
    // Scroll to the bottom after rendering the messages
    scrollToBottom();
  }, [allMessages]);

  // useEffect(() => {
  //   // Scroll to the bottom after rendering the messages
  //   scrollToBottom();
  // }, [chatMessages]);

  // useEffect(() => {
  //   // Concatenate new messages with existing ones
  //   if (Array.isArray(chatMessages)) {
  //     const updatedMessages = [...allMessages, ...chatMessages];
  //     console.log(updatedMessages);
  //     setFinalMessages(updatedMessages);
  //   }
  // }, [chatMessages]);

  // useEffect(() => {
  //   // setAllMessages([]);
  //   if (!isAtTop) {
  //     setAllMessages([]);
  //   }
  // }, [chatMessages, isAtTop]);

  const handleCallClick = (contactInfo) => {
    window.location.href = `tel:${contactInfo}`;
  };

  const handleBackPress = () => {
    dispatch(toggleSidebar());
  };
  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
    handleClose();
  };

  const handleSidebarClose = () => {
    setSidebarOpen(false);
  };

  const handleMessageInfoToggle = () => {
    setMessageInfoOpen(!messageInfoOpen);
  };

  const throttle = (func, delay) => {
    let lastExecTime = 0;

    return function () {
      const now = new Date().getTime();

      if (now - lastExecTime > delay) {
        func.apply(this, arguments);
        lastExecTime = now;
      }
    };
  };
  // Create a persistent throttled function
  const throttledHandleInputChange = throttle((value) => {
    setInputValue(value);
  }, 300);

  const handleInputChange = (event) => {
    // setInputValue(event.target.value);
    throttledHandleInputChange(event.target.value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAttachment = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleDropDown = (event) => {
    setAnchorE3(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMessageInfoClose = () => {
    // setAnchorE3(null);
    setMessageInfoOpen(false);
  };
  const handleAttachmentClose = () => {
    setAnchorE2(null);
  };
  const handleDropDownClose = () => {
    setAnchorE3(null);
  };
  const handleContactInfo = () => {
    // Implement your logout logic here
    handleClose();
  };

  const handleDocumentAttachment = (type) => {
    const inputElement = document.createElement("input");
    inputElement.type = "file";

    switch (type) {
      case "photoVideo":
        inputElement.accept = ".bmp, .png, .jpeg, .jpg, .mp4, .3gp, .mov";
        break;
      case "audio":
        inputElement.accept = ".mp3";
        break;
      case "document":
        inputElement.accept = "*/*";
        break;
      default:
        inputElement.accept = "image/*, video/*, audio/*, .pdf, .doc, .docx";
        break;
    }

    inputElement.multiple = false;
    inputElement.addEventListener("change", handleFileChange);

    // Trigger the file input dialog
    inputElement.click();
    handleAttachmentClose();
  };

  const handleMsgInfo = (statusTiming) => {
    dispatch(setSelectedMessageInfo(statusTiming));
    handleMessageInfoToggle();
    handleDropDownClose();
  };
  const handleMsgForward = () => {
    handleDropDownClose();
  };

  const handleSendMessage = () => {
    if (inputValue.trim() === "") {
      toast.warn("Input cannot be empty", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } else {
      const payload = {
        token: token,
        chatId: chatId,
        message: {
          to: contactInfo,
          message: {
            type: "text",
            text: inputValue,
          },
        },
      };

      dispatch(sendMessage(payload)).then((res) =>
        console.log("message res for text message", res)
      );
      setInputValue("");
    }
  };

  const handleSendDocument = () => {
    const sizeInMB = fileSize / (1024 * 1024);
    if (sizeInMB > 10) {
      toast.warn(
        "File size limit exceeded. Please upload a file less than 10 MB!",
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    } else {
      setAttachmentLoader(true);
      const payload = {
        token: token,
        chatId: chatId,
        message: {
          to: contactInfo,
          filename: attachmentFileName,
          message: {
            type: messageType,
            media: base64Only,
          },
        },
      };
      console.log("check message payload", payload);
      dispatch(sendMessage(payload)).then((res) => {
        setAttachmentLoader(false);
        closeAttachmentModal();
      });
      setInputValue("");
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      if (inputValue.trim() === "") {
        toast.warn("Input cannot be empty", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else {
        handleSendMessage();
      }
    }
  };
  const ReceivedChat = ({
    message,
    time,
    status,
    statusTiming,
    template = false,
  }) => {
    return (
      <div className="chatbox-receivedChat">
        <p className="chatbox-textMessage">
          {template ? (
            `Template- Template Name: ${message?.template}`
          ) : (
            <TextLinkFormatter text={message} />
          )}
        </p>
        <p className="chatbox-textTime">{time}</p>
      </div>
    );
  };

  const SentChat = ({
    message,
    time,
    status,
    statusTiming,
    template = false,
  }) => {
    const [isHovered, setIsHovered] = useState(false);

    let statusIcon;
    switch (status) {
      case 1:
        statusIcon = (
          <DoneIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14 }}
          />
        );
        break;
      case 2:
        statusIcon = (
          <DoneAllIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14 }}
          />
        );
        break;
      case 3:
        statusIcon = (
          <DoneAllIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14, color: "#003bd2" }}
          />
        );
        break;
      case 4:
        statusIcon = (
          <ErrorIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14, color: "#DF2C2B" }}
          />
        );
        break;
      default:
        statusIcon = null;
    }
    return (
      <div
        className="chatbox-sentChat"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <p className="chatbox-textMessage">
          {template ? (
            `Template- Template Name: ${message?.template}`
          ) : (
            <TextLinkFormatter text={message} />
          )}

          {/* {<TextLinkFormatter text={message} />} */}
        </p>
        <p
          className="chatbox-textTime"
          onClick={() => handleMsgInfo(statusTiming)}
        >
          {time}
        </p>

        {/* {isHovered ? (
          <KeyboardArrowDownIcon
            style={{ color: "#97ABAA" }}
            className="chatbox-downArrow"
            onClick={handleDropDown}
          />
        ) : ( */}
        {/* <DoubleTick className="chatbox-doubleTick" onClick={handleMsgInfo} /> */}
        {statusIcon}

        {/* )} */}
      </div>
    );
  };

  const ImageChatReceived = ({ content, time, status, statusTiming }) => {
    return (
      <div className="chatbox-imageChatRec-Container">
        <img
          src={content}
          alt="image-chat"
          className="chatbox-imageChatRec-image"
          onClick={() => openModal(content)}
        />
        <p className="chatbox-imageChatRec-time">{time}</p>
        {/* Modal content */}
        <ImageModal isModalOpen={isModalOpen} closeModal={closeModal} />
      </div>
    );
  };
  const ImageChatSent = ({ content, time, status, statusTiming }) => {
    const [isHovered, setIsHovered] = useState(false);

    let statusIcon;
    switch (status) {
      case 1:
        statusIcon = (
          <DoneIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14 }}
          />
        );
        break;
      case 2:
        statusIcon = (
          <DoneAllIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14 }}
          />
        );
        break;
      case 3:
        statusIcon = (
          <DoneAllIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14, color: "#003bd2" }}
          />
        );
        break;
      case 4:
        statusIcon = (
          <ErrorIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14, color: "#DF2C2B" }}
          />
        );
        break;
      default:
        statusIcon = null;
    }
    return (
      <div
        className="chatbox-imageChatSent-Container"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* {isHovered && (
          <KeyboardArrowDownIcon
            style={{ color: "#97ABAA" }}
            className="chatbox-imageChatSent-downArrow"
            onClick={handleDropDown}
          />
        )} */}
        <img
          src={content}
          alt="image-chat"
          className="chatbox-imageChatSent-image"
          onClick={() => openModal(content)}
        />
        <p
          className="chatbox-imageChatSent-time"
          onClick={() => handleMsgInfo(statusTiming)}
        >
          {time} {statusIcon}
        </p>
        {/* Modal content */}
        <ImageModal isModalOpen={isModalOpen} closeModal={closeModal} />
      </div>
    );
  };

  const VideoChatReceived = ({ content, time, status, statusTiming }) => {
    const [isHovered, setIsHovered] = useState(false);
    const videoRef = useRef(null);
    const [isPlaying, setPlaying] = useState(false);

    const togglePlay = () => {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
      setPlaying(!isPlaying);
    };
    return (
      <div
        className="chatbox-videoChatRec-Container"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* <div className="chatbox-videoChatRec-downloadContainer">
          <DownloadIcon style={{ color: "white" }} />
          <p className="chatbox-videoChatRec-downloadText">6 MB</p>
        </div> */}
        <video
          ref={videoRef}
          className="reel-player"
          controls={true}
          loop={true}
          onClick={togglePlay}
          style={{ width: "95%", height: "95%", objectFit: "cover" }}
        >
          <source src={content} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <button className="play-button" onClick={togglePlay}>
          {isHovered && (
            <>
              {isPlaying ? (
                <PauseCircleOutlineIcon style={{ width: 50, height: 50 }} />
              ) : (
                <PlayCircleOutlineIcon style={{ width: 50, height: 50 }} />
              )}
            </>
          )}
        </button>
        {/* <img
          src={require("../../assets/images/sampleNotDownloadedBlur.jpg")}
          src={content}
          alt="image-chat"
          className="chatbox-videoChatRec-video"
        /> */}
        <div className="chatbox-videoChatRec-bottomContainer">
          {/* <div className="chatbox-videoChatRec-durationContainer">
            <VideocamIcon style={{ width: 20, height: 20, color: "white" }} />
            <p className="chatbox-videoChatRec-duration">0.33</p>
          </div> */}

          <p className="chatbox-videoChatRec-time">{time}</p>
        </div>
      </div>
    );
  };
  const VideoChatSent = ({ content, time, status, statusTiming }) => {
    const [isArrowHovered, setIsArrowHovered] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const videoRef = useRef(null);
    const [isPlaying, setPlaying] = useState(false);

    let statusIcon;
    switch (status) {
      case 1:
        statusIcon = (
          <DoneIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14 }}
          />
        );
        break;
      case 2:
        statusIcon = (
          <DoneAllIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14 }}
          />
        );
        break;
      case 3:
        statusIcon = (
          <DoneAllIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14, color: "#003bd2" }}
          />
        );
        break;
      case 4:
        statusIcon = (
          <ErrorIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14, color: "#DF2C2B" }}
          />
        );
        break;
      default:
        statusIcon = null;
    }

    const handleMouseEnter = () => {
      setIsHovered(true);
      setIsArrowHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
      setIsArrowHovered(false);
    };

    const togglePlay = () => {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
      setPlaying(!isPlaying);
    };
    return (
      <div
        className="chatbox-videoChatSent-Container"
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseLeave()}
      >
        {/* <div className="chatbox-videoChatSent-downloadContainer">
          <DownloadIcon style={{ color: "white" }} />
          <p className="chatbox-videoChatSent-downloadText">6 MB</p>
        </div> */}
        {/* {isArrowHovered && (
          <KeyboardArrowDownIcon
            style={{ color: "#97ABAA" }}
            className="chatbox-videoChatSent-downArrow"
            onClick={handleDropDown}
          />
        )} */}
        <video
          ref={videoRef}
          className="reel-player"
          controls={true}
          loop={true}
          onClick={togglePlay}
          style={{ width: "95%", height: "95%", objectFit: "cover" }}
        >
          <source src={content} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <button className="play-button" onClick={togglePlay}>
          {isHovered && (
            <>
              {isPlaying ? (
                <PauseCircleOutlineIcon style={{ width: 50, height: 50 }} />
              ) : (
                <PlayCircleOutlineIcon style={{ width: 50, height: 50 }} />
              )}
            </>
          )}
        </button>
        {/* <img
          src={require("../../assets/images/sampleNotDownloadedBlur.jpg")}
          src={content}
          alt="image-chat"
          className="chatbox-videoChatSent-video"
        /> */}
        <div className="chatbox-videoChatSent-bottomContainer">
          {/* <div className="chatbox-videoChatSent-durationContainer">
            <VideocamIcon style={{ width: 20, height: 20, color: "white" }} />
            <p className="chatbox-videoChatSent-duration">{0.33}</p>
          </div> */}

          <p
            className="chatbox-videoChatSent-time"
            onClick={() => handleMsgInfo(statusTiming)}
          >
            {time} {statusIcon}
          </p>
        </div>
      </div>
    );
  };

  const AudioChatReceived = ({ time, mp3Url, status, statusTiming }) => {
    return (
      <div className="chatbox-audioChatRec-container">
        <div className="chatbox-audioChatRec-iconContainer">
          {/* <PlayArrowIcon
            style={{ color: "#9C8D8D", width: 40, height: 40, marginLeft: 5 }}
          />
          <AudiotrackIcon style={{ color: "#66D263", marginRight: 10 }} /> */}
          <audio controls>
            <source src={mp3Url} type="audio/mp3" />
            Your browser does not support the audio tag.
          </audio>
        </div>

        <div className="chatbox-audioChatRec-timeContainer">
          <p className="chatbox-audioChatRec-duration">0.10</p>
          <p className="chatbox-audioChatRec-time">{time}</p>
        </div>
      </div>
    );
  };

  const AudioChatSent = ({ time, mp3Url, status, statusTiming }) => {
    const [isHovered, setIsHovered] = useState(false);
    let statusIcon;
    switch (status) {
      case 1:
        statusIcon = (
          <DoneIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14 }}
          />
        );
        break;
      case 2:
        statusIcon = (
          <DoneAllIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14 }}
          />
        );
        break;
      case 3:
        statusIcon = (
          <DoneAllIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14, color: "#003bd2" }}
          />
        );
        break;
      case 4:
        statusIcon = (
          <ErrorIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14, color: "#DF2C2B" }}
          />
        );
        break;
      default:
        statusIcon = null;
    }

    return (
      <div
        className="chatbox-audioChatSent-container"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* {isHovered && (
          <KeyboardArrowDownIcon
            style={{ color: "#97ABAA" }}
            className="chatbox-audioChatSent-downArrow"
            onClick={handleDropDown}
          />
        )} */}
        <div className="chatbox-audioChatSent-iconContainer">
          {/* <PlayArrowIcon
            style={{ color: "#9C8D8D", width: 40, height: 40, marginLeft: 5 }}
          />
         
          <AudiotrackIcon style={{ color: "#66D263", marginRight: 10 }} /> */}
          <audio controls>
            <source src={mp3Url} type="audio/mp3" />
            Your browser does not support the audio tag.
          </audio>
        </div>

        <div className="chatbox-audioChatSent-timeContainer">
          {/* <p className="chatbox-audioChatSent-duration">0.10</p> */}
          <p></p>
          <p
            className="chatbox-audioChatSent-time"
            onClick={() => handleMsgInfo(statusTiming)}
          >
            {time} {statusIcon}
          </p>
        </div>
      </div>
    );
  };

  const AttachmentReceived = ({ content, time, status, statusTiming }) => {
    const fileName = content.filename;
    const parts = fileName.split(".");
    const fileType = parts[parts.length - 1];

    let iconComponent;

    switch (fileType) {
      case "pdf":
        iconComponent = (
          <PDF style={{ width: 35, height: 35, marginLeft: 10 }} />
        );
        break;
      case "txt":
        iconComponent = (
          <TXT
            style={{
              width: 35,
              height: 35,
              marginLeft: 10,
              color: "#79909B",
            }}
          />
        );
        break;
      case "jpg":
        iconComponent = (
          <InsertDriveFileIcon
            style={{
              width: 35,
              height: 35,
              marginLeft: 10,
              color: "#79909B",
            }}
          />
        );
        break;
      default:
        iconComponent = (
          <InsertDriveFileIcon
            style={{
              width: 35,
              height: 35,
              marginLeft: 10,
              color: "#79909B",
            }}
          />
        );
    }
    return (
      <div className="chatbox-attachmentRec-Container">
        <div className="chatbox-attachmentRec-wrapper">
          <div className="chatbox-attachmentRec-wrapper-left">
            {iconComponent}

            <div className="chatbox-attachmentRec-textContainer">
              <p
                className="chatbox-attachmentRec-fileName"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {fileName}
              </p>

              <div className="chatbox-attachmentRec-detailcontainer">
                {/* {fileType === "pdf" && (
                  <>
                    <p className="chatbox-attachmentRec-detailText">17 pages</p>
                    <FiberManualRecordIcon
                      style={{ color: "#79909B", width: 5, height: 5 }}
                    />
                  </>
                )} */}

                <p className="chatbox-attachmentRec-detailText">
                  {fileType?.toUpperCase()}
                </p>
                {/* <FiberManualRecordIcon
                  style={{ color: "#79909B", width: 5, height: 5 }}
                />
                <p className="chatbox-attachmentRec-detailText">12 MB</p> */}
              </div>
            </div>
          </div>
          <DownloadForOfflineIcon
            style={{ color: "#92AC9D", width: 45, height: 45, marginRight: 10 }}
            // onClick={() => handleDownload(content?.mediaUrl, fileName)}
            onClick={() =>
              openDownloadConfirmationModal(content?.mediaUrl, fileName)
            }
          />
        </div>
        <div className="chatbox-attachmentRec-timeContainer">
          <p className="chatbox-attachmentRec-time">{time}</p>
        </div>
        {/* modal content */}
        <ConfirmationModal
          text={"Are you sure you want to Download the file?"}
          isModalOpen={isDownloadModalOpen}
          closeModal={closeDownloadConfirmationModal}
          onYes={handleDownload}
          yesBtnText={"Yes, Download it"}
        />
      </div>
    );
  };

  const AttachmentSent = ({ content, time, status, statusTiming }) => {
    let statusIcon;
    switch (status) {
      case 1:
        statusIcon = (
          <DoneIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14 }}
          />
        );
        break;
      case 2:
        statusIcon = (
          <DoneAllIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14 }}
          />
        );
        break;
      case 3:
        statusIcon = (
          <DoneAllIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14, color: "#003bd2" }}
          />
        );
        break;
      case 4:
        statusIcon = (
          <ErrorIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14, color: "#DF2C2B" }}
          />
        );
        break;
      default:
        statusIcon = null;
    }
    const fileName = content?.filename;
    const parts = fileName.split(".");
    const fileType = parts[parts.length - 1];

    // const handleDownload = () => {
    //   FileDownloader(content?.mediaUrl, fileName);
    // };

    const [isHovered, setIsHovered] = useState(false);

    let iconComponent;

    switch (fileType) {
      case "pdf":
        iconComponent = (
          <PDF style={{ width: 35, height: 35, marginLeft: 10 }} />
        );
        break;
      case "txt":
        iconComponent = (
          <TXT
            style={{
              width: 35,
              height: 35,
              marginLeft: 10,
              color: "#79909B",
            }}
          />
        );
        break;
      case "jpg":
        iconComponent = (
          <InsertDriveFileIcon
            style={{
              width: 35,
              height: 35,
              marginLeft: 10,
              color: "#79909B",
            }}
          />
        );
        break;
      default:
        iconComponent = (
          <InsertDriveFileIcon
            style={{
              width: 35,
              height: 35,
              marginLeft: 10,
              color: "#79909B",
            }}
          />
        );
    }
    return (
      <div
        className="chatbox-attachmentSent-Container"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="chatbox-attachmentSent-wrapper">
          <div className="chatbox-attachmentSent-wrapper-left">
            {iconComponent}

            <div className="chatbox-attachmentSent-textContainer">
              <p
                className="chatbox-attachmentSent-fileName"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {fileName}
              </p>

              <div className="chatbox-attachmentSent-detailcontainer">
                {/* {fileType === "pdf" && (
                  <>
                    <p className="chatbox-attachmentSent-detailText">
                      17 pages
                    </p>
                    <FiberManualRecordIcon
                      style={{ color: "#79909B", width: 5, height: 5 }}
                    />
                  </>
                )} */}

                <p className="chatbox-attachmentSent-detailText">
                  {fileType?.toUpperCase()}
                </p>
                {/* <FiberManualRecordIcon
                  style={{ color: "#79909B", width: 5, height: 5 }}
                />
                <p className="chatbox-attachmentSent-detailText">12 MB</p> */}
              </div>
            </div>
          </div>
          <DownloadForOfflineIcon
            style={{ color: "#92AC9D", width: 45, height: 45, marginRight: 10 }}
            // onClick={() => handleDownload(content?.mediaUrl, fileName)}
            onClick={() =>
              openDownloadConfirmationModal(content?.mediaUrl, fileName)
            }
          />
          {/* {isHovered && (
            <KeyboardArrowDownIcon
              style={{ color: "#97ABAA" }}
              className="chatbox-attachmentSent-downArrow"
              onClick={handleDropDown}
            />
          )} */}
        </div>
        <div className="chatbox-attachmentSent-timeContainer">
          <p
            className="chatbox-attachmentSent-time"
            onClick={() => handleMsgInfo(statusTiming)}
          >
            {time}
          </p>
          <div className="chatbox-attachmentSent-statusIcon">{statusIcon}</div>
        </div>
        {/* modal content */}
        <ConfirmationModal
          isModalOpen={isDownloadModalOpen}
          closeModal={closeDownloadConfirmationModal}
          onYes={handleDownload}
          yesBtnText={"Yes, Download it"}
        />
      </div>
    );
  };

  const MapPreview = ({
    content,
    time,
    status,
    statusTiming,
    sender = false,
  }) => {
    let statusIcon;
    switch (status) {
      case 1:
        statusIcon = (
          <DoneIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14 }}
          />
        );
        break;
      case 2:
        statusIcon = (
          <DoneAllIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14 }}
          />
        );
        break;
      case 3:
        statusIcon = (
          <DoneAllIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14, color: "#003bd2" }}
          />
        );
        break;
      case 4:
        statusIcon = (
          <ErrorIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14, color: "#DF2C2B" }}
          />
        );
        break;
      default:
        statusIcon = null;
    }
    const [maploading, setMapLoading] = useState(true);
    const latitude = content?.location?.latitude;
    const longitude = content?.location?.longitude;
    const mapUrl = `https://www.google.com/maps/embed/v1/view?key=YOUR_API_KEY&center=${latitude},${longitude}&zoom=18&maptype=satellite`;

    const handleLoad = () => {
      setMapLoading(false);
    };

    return (
      <div
        className={sender ? "chatbox-mapPreview-sender" : "chatbox-mapPreview"}
      >
        <div className="chatbox-mapPreview-inner">
          {maploading && (
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#4fa94d"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              wrapperClass=""
            />
          )}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.3164333497953!2d77.4808743746437!3d28.500125890082547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cebb494b215b1%3A0x8e756b471ec8afeb!2sRevron%20Tech!5e0!3m2!1sen!2sin!4v1705338048501!5m2!1sen!2sin"
            // src={mapUrl}
            width="600"
            height="450"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            onLoad={handleLoad}
          ></iframe>
        </div>
        <div className="chatbox-mapPreview-textContainer">
          <p className="chatbox-mapPreview-title">Location</p>
          <p
            className={
              sender
                ? "chatbox-mapPreview-time-sent"
                : "chatbox-mapPreview-time"
            }
            onClick={sender ? () => handleMsgInfo(statusTiming) : null}
          >
            {time}
          </p>
          <div className="chatbox-mapPreview-statusIcon">{statusIcon}</div>
        </div>
      </div>
    );
  };

  const Sticker = ({ content, time, status, statusTiming, sender = false }) => {
    let statusIcon;
    switch (status) {
      case 1:
        statusIcon = (
          <DoneIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14 }}
          />
        );
        break;
      case 2:
        statusIcon = (
          <DoneAllIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14 }}
          />
        );
        break;
      case 3:
        statusIcon = (
          <DoneAllIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14, color: "#003bd2" }}
          />
        );
        break;
      case 4:
        statusIcon = (
          <ErrorIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14, color: "#DF2C2B" }}
          />
        );
        break;
      default:
        statusIcon = null;
    }
    return (
      <div className={sender ? "chatbox-sticker-sender" : "chatbox-sticker"}>
        <img
          src={content?.mediaUrl}
          alt="sticker"
          className="chatbox-sticker-inner"
          // onClick={openModal}
        />
        <p
          className="chatbox-sticker-time"
          onClick={sender ? () => handleMsgInfo(statusTiming) : null}
        >
          {time} {statusIcon}
        </p>
      </div>
    );
  };
  const ContactCard = ({
    content,
    time,
    status,
    statusTiming,
    sender = false,
  }) => {
    let statusIcon;
    switch (status) {
      case 1:
        statusIcon = (
          <DoneIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14 }}
          />
        );
        break;
      case 2:
        statusIcon = (
          <DoneAllIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14 }}
          />
        );
        break;
      case 3:
        statusIcon = (
          <DoneAllIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14, color: "#003bd2" }}
          />
        );
        break;
      case 4:
        statusIcon = (
          <ErrorIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14, color: "#DF2C2B" }}
          />
        );
        break;
      default:
        statusIcon = null;
    }
    const [isHovered, setIsHovered] = useState(false);
    return (
      <div
        className={
          sender === true
            ? "chatbox-contactSent-Container"
            : "chatbox-contactRec-Container"
        }
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="chatbox-contactRec-wrapper">
          {content?.contacts.map((contact, index) => (
            <div key={index} className="chatbox-contact-individual">
              <AccountBoxIcon
                style={{
                  color: "#79909B",
                  width: 20,
                  height: 20,
                  marginLeft: 10,
                  marginTop: 15,
                }}
              />
              <div>
                <p className="chatbox-contactRec-text">{contact?.name}</p>
                {contact?.phones.map((phone, phoneIndex) => (
                  <p
                    key={phoneIndex}
                    className="chatbox-contactRec-phoneNum"
                    onClick={() => handleCallClick(phone)}
                  >
                    {phone}
                  </p>
                ))}
              </div>
            </div>
          ))}
          {/* {sender === true && isHovered && (
            <KeyboardArrowDownIcon
              style={{ color: "#97ABAA" }}
              className="chatbox-contactRec-downArrow"
              onClick={handleDropDown}
            />
          )} */}
        </div>
        <div className="chatbox-contactRec-timeContainer">
          <p
            className="chatbox-contactRec-time"
            onClick={sender ? () => handleMsgInfo(statusTiming) : null}
          >
            {time} {statusIcon}
          </p>
          {sender === true && <DoubleTick />}
        </div>
      </div>
    );
  };

  const UnknownTypeComponent = ({
    content,
    time,
    status,
    statusTiming,
    sender = false,
  }) => {
    let statusIcon;
    switch (status) {
      case 1:
        statusIcon = (
          <DoneIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14 }}
          />
        );
        break;
      case 2:
        statusIcon = (
          <DoneAllIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14 }}
          />
        );
        break;
      case 3:
        statusIcon = (
          <DoneAllIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14, color: "#003bd2" }}
          />
        );
        break;
      case 4:
        statusIcon = (
          <ErrorIcon
            className="chatbox-doubleTick"
            onClick={() => handleMsgInfo(statusTiming)}
            style={{ width: 14, height: 14, color: "#DF2C2B" }}
          />
        );
        break;
      default:
        statusIcon = null;
    }
    return (
      <div
        className={
          sender ? "chatbox-unknownType-sender" : "chatbox-unknownType"
        }
      >
        <div className="chatbox-unknownType-inner">
          <p className="chatbox-unknownType-strigified">
            {typeof content === "object" ? JSON.stringify(content) : content}
          </p>
        </div>

        <p
          className="chatbox-unknownType-time"
          onClick={sender ? () => handleMsgInfo(statusTiming) : null}
        >
          {time}
        </p>
      </div>
    );
  };
  return (
    <section className="chatbox-container">
      <>
        <div className="chatbox-header">
          <div className={isMobile ? "chatbox-header-left" : null}>
            {isMobile && <KeyboardBackspaceIcon onClick={handleBackPress} />}

            <p className="chatbox-personName">{contactName}</p>
          </div>

          <MenuIcon className="chatbox-menuIcon" onClick={handleClick} />
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleSidebarToggle}>Contact Info</MenuItem>
          </Menu>
        </div>
        <div
          className="chatbox-chatSection"
          ref={scrollableRef}
          onScroll={handleScroll}
        >
          {showButton && (
            <div
              className="chatbox-chatSection-downArrow"
              onClick={scrollToBottom}
            >
              <KeyboardArrowDownIcon />
            </div>
          )}

          {loading && <Loader />}

          {finalMessages
            ? [...finalMessages].reverse().map((message) => {
                const messageType = message?.message?.type;
                const formattedTime = TimeFormatter(message?.created_on, true);
                const mediaUrl = message?.message?.mediaUrl;
                const caption = message?.message?.caption;
                const messageContent = message?.message; // for default case
                const readStatus = message?.status;
                switch (messageType) {
                  case "text":
                    return message.sender === 1 ? (
                      <ReceivedChat
                        key={message?.id}
                        message={message?.message?.text}
                        time={formattedTime}
                        status={readStatus}
                        statusTiming={message}
                      />
                    ) : (
                      <SentChat
                        key={message?.id}
                        message={message?.message?.text}
                        time={formattedTime}
                        status={readStatus}
                        statusTiming={message}
                      />
                    );
                  case "audio":
                    return message.sender === 1 ? (
                      <AudioChatReceived
                        key={message?.id}
                        mp3Url={mediaUrl}
                        time={formattedTime}
                        status={readStatus}
                        statusTiming={message}
                      />
                    ) : (
                      <AudioChatSent
                        key={message?.id}
                        mp3Url={mediaUrl}
                        time={formattedTime}
                        status={readStatus}
                        statusTiming={message}
                      />
                    );
                  case "video":
                    return message.sender === 1 ? (
                      <VideoChatReceived
                        key={message?.id}
                        content={mediaUrl}
                        time={formattedTime}
                        status={readStatus}
                        statusTiming={message}
                      />
                    ) : (
                      <VideoChatSent
                        key={message.id}
                        content={mediaUrl}
                        time={formattedTime}
                        status={readStatus}
                        statusTiming={message}
                      />
                    );
                  case "image":
                    return message.sender === 1 ? (
                      <ImageChatReceived
                        key={message?.id}
                        content={mediaUrl}
                        time={formattedTime}
                        status={readStatus}
                        statusTiming={message}
                      />
                    ) : (
                      <ImageChatSent
                        key={message?.id}
                        content={mediaUrl}
                        time={formattedTime}
                        status={readStatus}
                        statusTiming={message}
                      />
                    );
                  case "document":
                    return message.sender === 1 ? (
                      <AttachmentReceived
                        key={message?.id}
                        content={message?.message}
                        time={formattedTime}
                        status={readStatus}
                        statusTiming={message}
                      />
                    ) : (
                      <AttachmentSent
                        key={message?.id}
                        content={message?.message}
                        time={formattedTime}
                        status={readStatus}
                        statusTiming={message}
                      />
                    );
                  case "location":
                    return message.sender === 1 ? (
                      <MapPreview
                        key={message?.id}
                        content={message?.message}
                        time={formattedTime}
                        status={readStatus}
                        statusTiming={message}
                      />
                    ) : (
                      <MapPreview
                        key={message?.id}
                        content={message?.message}
                        time={formattedTime}
                        sender={true}
                        status={readStatus}
                        statusTiming={message}
                      />
                    );
                  case "sticker":
                    return message.sender === 1 ? (
                      <Sticker
                        key={message?.id}
                        content={message?.message}
                        time={formattedTime}
                        status={readStatus}
                        statusTiming={message}
                      />
                    ) : (
                      <Sticker
                        key={message?.id}
                        content={message?.message}
                        time={formattedTime}
                        sender={true}
                        status={readStatus}
                        statusTiming={message}
                      />
                    );
                  case "contacts":
                    return message.sender === 1 ? (
                      <ContactCard
                        key={message?.id}
                        content={message?.message}
                        time={formattedTime}
                        status={readStatus}
                        statusTiming={message}
                      />
                    ) : (
                      <ContactCard
                        key={message?.id}
                        content={message?.message}
                        time={formattedTime}
                        sender={true}
                        status={readStatus}
                        statusTiming={message}
                      />
                    );
                  case "template":
                    return message.sender === 1 ? (
                      <ReceivedChat
                        key={message?.id}
                        message={message?.message}
                        time={formattedTime}
                        template={true}
                        status={readStatus}
                        statusTiming={message}
                      />
                    ) : (
                      <SentChat
                        key={message?.id}
                        message={message?.message}
                        time={formattedTime}
                        template={true}
                        status={readStatus}
                        statusTiming={message}
                      />
                    );
                  default:
                    return message.sender === 1 ? (
                      <ReceivedChat
                        key={message?.id}
                        message={
                          typeof messageContent === "object"
                            ? JSON.stringify(messageContent)
                            : messageContent
                        }
                        time={formattedTime}
                        status={readStatus}
                        statusTiming={message}
                      />
                    ) : (
                      <SentChat
                        key={message?.id}
                        message={
                          typeof messageContent === "object"
                            ? JSON.stringify(messageContent)
                            : messageContent
                        }
                        time={formattedTime}
                        status={readStatus}
                        statusTiming={message}
                      />
                    );
                }
              })
            : null}
        </div>
      </>
      <div className="chatbox-chatpad">
        <div className="chatbox-chatpad-innerContainer">
          {contactInfo !== null && canSendMessages && (
            <>
              <AttachIcon
                className="chatbox-attachIcon"
                onClick={handleAttachment}
              />
            </>
          )}

          <div className="chatbox-inputContainer">
            <input
              type="text"
              className="chatbox-type-input"
              value={inputValue}
              onChange={handleInputChange}
              maxLength={1024}
              placeholder={
                contactInfo
                  ? canSendMessages
                    ? "Type a message"
                    : "You are not allowed to send messages"
                  : "Select a Contact to start sending messages"
              }
              disabled={contactInfo === null || canSendMessages === false}
              onKeyDown={handleEnterKeyPress}
            />
          </div>
          {contactInfo !== null && canSendMessages && (
            <SendIcon className="chatbox-sendBtn" onClick={handleSendMessage} />
          )}
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorE2}
          open={Boolean(anchorE2)}
          onClose={handleAttachmentClose}
        >
          <MenuItem onClick={() => handleDocumentAttachment("document")}>
            <ListItemIcon>
              <DescriptionIcon style={{ color: "#7F66FF" }} />
            </ListItemIcon>
            Document
          </MenuItem>
          <MenuItem onClick={() => handleDocumentAttachment("photoVideo")}>
            <ListItemIcon>
              <CollectionsIcon style={{ color: "#417BFD" }} />
            </ListItemIcon>
            Photos & Videos
          </MenuItem>
          <MenuItem onClick={() => handleDocumentAttachment("audio")}>
            <ListItemIcon>
              <AudiotrackIcon style={{ color: "#E62E72" }} />
            </ListItemIcon>
            Audio
          </MenuItem>
          {/* <MenuItem onClick={handleDocumentAttachment("contact")}>
            <ListItemIcon>
              <PersonIcon style={{ color: "#4F9EE3" }} />
            </ListItemIcon>
            Contact
          </MenuItem> */}
        </Menu>
        {/* modal content for attachment */}
        <AttachmentDetailModal
          isModalOpen={isAttachmentModalOpen}
          closeModal={closeAttachmentModal}
          content={selectedFiles}
          onClick={handleSendDocument}
          loadStatus={attachmentLoader}
        />
      </div>
      <RightSideBar open={sidebarOpen} onClose={handleSidebarClose} />
      <MessageInfo open={messageInfoOpen} onClose={handleMessageInfoClose} />
    </section>
  );
};

export default ChatBox;
