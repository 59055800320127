import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseUrl, EndPoint } from "../../constants/constants";

const API_ENDPOINT = BaseUrl + EndPoint.userChat;

const initialState = {
  status: "idle",
  error: null,
};

export const sendMessage = createAsyncThunk("message/send", async (payload) => {
  const response = await fetch(`${API_ENDPOINT}/${payload.chatId}/message`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.token}`,
    },
    body: JSON.stringify(payload.message),
  });

  if (!response.ok) {
    throw new Error("Failed to send message");
  }

  return response.json();
});

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendMessage.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default messageSlice.reducer;
