import React, { useEffect } from "react";
import "./ConfirmationModal.css";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const ConfirmationModal = ({
  text,
  isModalOpen,
  closeModal,
  onYes,
  yesBtnText,
}) => {
  useEffect(() => {
    Modal.setAppElement("#root");
  }, []);
  const handleYesClick = () => {
    onYes();
    closeModal();
  };
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel="Confirmation Modal"
      style={customStyles}
    >
      <div className="confirmationModal-Container">
        <div className="confirmationModal-header">
          <p className="confirmationModal-title">Confirmation</p>
          <CloseIcon onClick={closeModal} className="confirmationModal-icon" />
        </div>
        <div className="confirmationModal-TitlePreview">
          <p className="confirmationModal-text">{text}</p>
        </div>
        <div className="confirmationModal-btn-container">
          <p className="confirmationModal-btn-cancel" onClick={closeModal}>
            Cancel
          </p>
          <p className="confirmationModal-btn-yes" onClick={handleYesClick}>
            {yesBtnText}?
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
