import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseUrl, EndPoint } from "../../constants/constants";

const API_ENDPOINT = BaseUrl + EndPoint.userChatList;

const initialState = {
  chatList: [],
  status: "idle",
  error: null,
  selectedChatId: null,
  selectedContactInfo: null,
  selectedName: null,
  canSendMessages: true,
  selectedMessageInfo: null,
  selectedImagePreview: null,
  selectedAttachmentUrl: null,
  selectedAttachmentFileName: null,
};

export const fetchChatList = createAsyncThunk(
  "chat/fetchChatList",
  async ({ token, page }) => {
    try {
      const response = await axios.get(API_ENDPOINT, {
        params: { o: page },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setSelectedChatId: (state, action) => {
      state.selectedChatId = action.payload;
    },
    setSelectedContactInfo: (state, action) => {
      state.selectedContactInfo = action.payload;
    },
    setSelectedName: (state, action) => {
      state.selectedName = action.payload;
    },
    setCanSendMessages: (state, action) => {
      state.canSendMessages = action.payload;
    },
    setSelectedMessageInfo: (state, action) => {
      state.selectedMessageInfo = action.payload;
    },
    setSelectedImagePreview: (state, action) => {
      state.selectedImagePreview = action.payload;
    },
    setSelectedAttachmentFileName: (state, action) => {
      state.selectedAttachmentFileName = action.payload;
    },
    setSelectedAttachmentUrl: (state, action) => {
      state.selectedAttachmentUrl = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChatList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.chatList = action.payload;
      })
      .addCase(fetchChatList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const {
  setSelectedChatId,
  setSelectedContactInfo,
  setSelectedName,
  setCanSendMessages,
  setSelectedMessageInfo,
  setSelectedImagePreview,
  setSelectedAttachmentFileName,
  setSelectedAttachmentUrl,
} = chatSlice.actions;
export default chatSlice.reducer;
