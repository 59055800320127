import React, { useEffect } from "react";
import "./ChatlistContainer.css";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../redux/slices/sidebarSlice";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ArticleIcon from "@mui/icons-material/Article";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import VideocamIcon from "@mui/icons-material/Videocam";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import GifIcon from "@mui/icons-material/Gif";
import HelpIcon from "@mui/icons-material/Help";
import { fetchChatMessages } from "../../redux/slices/chatMessageSlice";
import { fetchChatList } from "../../redux/slices/chatSlice";

const ChatlistContainer = ({
  name,
  message,
  time,
  unreadCount,
  onClick,
  isSelected,
}) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const chatId = useSelector((state) => state.chat.selectedChatId);
  const messageType = message?.type;

  // handle sidebar toogle in mobile view
  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
    onClick();
  };

  // useEffect(() => {
  //   if (unreadCount > 0) {
  //     dispatch(fetchChatMessages({ chatId, token }));
  //     dispatch(fetchChatList(token));
  //     console.log("triggered ffff");
  //   }
  // }, [isSelected]);

  return (
    <>
      <div
        className={
          isSelected
            ? "chatlist-chatContainer-selected"
            : "chatlist-chatContainer"
        }
        onClick={handleToggleSidebar}
      >
        <div className="chatlist-UpperSection">
          <p
            className={
              unreadCount > 0
                ? "chatlist-personName-unread"
                : "chatlist-personName"
            }
          >
            {name}
          </p>
          <p
            className={
              unreadCount > 0 ? "chatlist-time-unread" : "chatlist-time"
            }
          >
            {time}
          </p>
        </div>

        <div className="chatlist-lowerSection">
          {/* <p className="chatlist-message">{message}</p> */}
          {(() => {
            switch (messageType) {
              case "text":
                return (
                  <div className="chatlist-lastMessage">
                    <p
                      className={
                        unreadCount > 0
                          ? "chatlist-message-unread"
                          : "chatlist-message"
                      }
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {message?.text}
                    </p>
                  </div>
                );
              case "audio":
                return (
                  <div className="chatlist-lastMessage">
                    <VolumeDownIcon
                      style={{
                        width: 20,
                        height: 20,
                        color: "#8797A1",
                      }}
                    />
                    <p
                      className={
                        unreadCount > 0
                          ? "chatlist-message-unread"
                          : "chatlist-message"
                      }
                    >
                      Audio
                    </p>
                  </div>
                );
              case "video":
                return (
                  <div className="chatlist-lastMessage">
                    <VideocamIcon
                      style={{
                        width: 20,
                        height: 20,
                        color: "#8797A1",
                      }}
                    />
                    <p
                      className={
                        unreadCount > 0
                          ? "chatlist-message-unread"
                          : "chatlist-message"
                      }
                    >
                      Video
                    </p>
                  </div>
                );
              case "image":
                return (
                  <div className="chatlist-lastMessage">
                    <CameraAltIcon
                      style={{
                        width: 20,
                        height: 20,
                        color: "#8797A1",
                      }}
                    />
                    <p
                      className={
                        unreadCount > 0
                          ? "chatlist-message-unread"
                          : "chatlist-message"
                      }
                    >
                      Photo
                    </p>
                  </div>
                );
              case "document":
                return (
                  <div className="chatlist-lastMessage">
                    <ArticleIcon
                      style={{
                        width: 20,
                        height: 20,
                        color: "#8797A1",
                      }}
                    />
                    <p
                      className={
                        unreadCount > 0
                          ? "chatlist-message-unread"
                          : "chatlist-message"
                      }
                    >
                      Document
                    </p>
                  </div>
                );
              case "location":
                return (
                  <div className="chatlist-lastMessage">
                    <LocationOnIcon
                      style={{
                        width: 20,
                        height: 20,
                        color: "#8797A1",
                      }}
                    />
                    <p
                      className={
                        unreadCount > 0
                          ? "chatlist-message-unread"
                          : "chatlist-message"
                      }
                    >
                      Location
                    </p>
                  </div>
                );
              case "sticker":
                return (
                  <div className="chatlist-lastMessage">
                    <GifIcon
                      style={{
                        width: 20,
                        height: 20,
                        color: "#8797A1",
                      }}
                    />
                    <p
                      className={
                        unreadCount > 0
                          ? "chatlist-message-unread"
                          : "chatlist-message"
                      }
                    >
                      Sticker
                    </p>
                  </div>
                );
              case "contacts":
                return (
                  <div className="chatlist-lastMessage">
                    <PermContactCalendarIcon
                      style={{
                        width: 20,
                        height: 20,
                        color: "#8797A1",
                      }}
                    />
                    <p
                      className={
                        unreadCount > 0
                          ? "chatlist-message-unread"
                          : "chatlist-message"
                      }
                    >
                      Contact
                    </p>
                  </div>
                );
              default:
                return (
                  <div className="chatlist-lastMessage">
                    <HelpIcon
                      style={{
                        width: 20,
                        height: 20,
                        color: "#8797A1",
                      }}
                    />
                    <p
                      className={
                        unreadCount > 0
                          ? "chatlist-message-unread"
                          : "chatlist-message"
                      }
                    >
                      Unknown File Type
                    </p>
                  </div>
                );
            }
          })()}

          {unreadCount > 0 && (
            <div className="chatlist-UnreadMsgContainer">
              <p className="chatlist-UnreadMessages">{unreadCount}</p>
            </div>
          )}
        </div>
      </div>
      {/* vertical line  */}
      {!isSelected && <div className="chatlist-verticalLine"></div>}
      {/* <div className="chatlist-verticalLine"></div> */}
    </>
  );
};

export default ChatlistContainer;
