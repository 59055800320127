import React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import "./ContactInfo.css";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

const ContactInfo = ({ open, onClose }) => {
  const contactInfo = useSelector((state) => state.chat.selectedContactInfo);
  const contactName = useSelector((state) => state.chat.selectedName);
  const handleCallClick = () => {
    window.location.href = `tel:${contactInfo}`;
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <div className="contactInfo-header">
        <CloseIcon style={{ color: "#3B4B54" }} onClick={onClose} />
        <p className="contactInfo-header-title">Contact info</p>
      </div>
      <div className="contactinfo-nameContainer">
        <p className="contactinfo-name">{contactName}</p>
        {contactInfo ? (
          <p className="contactinfo-phoneNum" onClick={handleCallClick}>
            +{contactInfo}
          </p>
        ) : (
          <p className="contactinfo-phoneNum">
            Select a Contact to see its Info
          </p>
        )}
      </div>
      <List className="contactinfo-listContainer">
        {/* <ListItem button>
          <ListItemText primary="About" />
        </ListItem>
        <ListItem button>
          <ListItemText primary="Contact" />
        </ListItem> */}
      </List>
    </Drawer>
  );
};

export default ContactInfo;
