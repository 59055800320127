import React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import "./NewChat.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import sampleContactList from "../../constants/sampleContactList";
const NewChat = ({ open, onClose }) => {
  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      className="newChat-container"
    >
      <div className="newChat-header">
        <ArrowBackIcon style={{ color: "white" }} onClick={onClose} />
        <p className="newChat-header-title">New chat</p>
      </div>

      <div className="newChat-list">
        {sampleContactList.map((item) => (
          <div className="newChat-listItem" key={item.id}>
            <p className="newChat-button-text">{item.personName}</p>
            <div className="newChat-verticalLine"></div>
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default NewChat;
