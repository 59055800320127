import React, { useEffect, useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/slices/authSlice";
import { useForm } from "react-hook-form";
import Loader from "../../components/Loader/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    let authToken = localStorage.getItem("authToken");
    if (authToken) {
      navigate("/");
    }
  });
  const handleSubmitClick = () => {
    handleSubmit(handleLogin)();
  };
  const notify = () =>
    toast("Successfully Logged In!", {
      autoClose: 2000,
    });

  const handleLogin = (data) => {
    const formData = {
      email: data.email,
      password: data.password,
    };

    setLoading(true);
    dispatch(loginUser(formData))
      .then((res) => {
        if (res?.payload?.status === 200) {
          setLoading(false);
          notify();
        } else {
          setLoading(false);
          toast.warn("Invalid Username or Password!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(handleLogin)} className="login-layout">
          <div className="login-form-container">
            <p className="login-form-appName">VR App</p>
            <p className="login-form-title">Log in to your account</p>

            <div className="login-form-inputLayout">
              <p className="login-form-inputTitle">Email</p>
              <div className="login-form-inputContainer">
                {/* <PhoneIcon style={{ width: 20, height: 20, color: "#808080" }} /> */}
                <EmailIcon
                  style={{ width: 20, height: 20, color: "#808080" }}
                />
                <input
                  {...register("email", { required: true })}
                  type="email"
                  className="login-form-inputText"
                  placeholder="Email"
                />
              </div>
            </div>
            <div className="login-form-inputLayout">
              <p className="login-form-inputTitle">Password</p>
              <div className="login-form-inputContainer">
                <LockIcon style={{ width: 20, height: 20, color: "#808080" }} />
                <input
                  {...register("password", { required: true })}
                  type={showPassword ? "text" : "Password"}
                  className="login-form-inputText"
                  placeholder="Password"
                />
                {showPassword ? (
                  <VisibilityOffIcon
                    style={{ color: "#808080" }}
                    onClick={handlePasswordVisibility}
                  />
                ) : (
                  <VisibilityIcon
                    style={{ color: "#808080" }}
                    onClick={handlePasswordVisibility}
                  />
                )}
              </div>
            </div>
            {/* button */}
            <div className="login-form-submit" onClick={handleSubmitClick}>
              <p className="login-form-submitText">Log In</p>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default Login;
