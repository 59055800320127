import React, { useEffect, useState } from "react";
import "./AttachmentDetailModal.css";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import TopicIcon from "@mui/icons-material/Topic";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Oval } from "react-loader-spinner";

const AttachmentDetailModal = ({
  isModalOpen,
  closeModal,
  content,
  onClick,
  loadStatus,
}) => {
  useEffect(() => {
    Modal.setAppElement("#root");
  }, []);
  const [filePreview, setFilePreview] = useState("");

  const handleFileRead = (e) => {
    const content = e.target.result;
    setFilePreview(content);
  };

  const handleFileSelect = () => {
    const reader = new FileReader();
    reader.onloadend = handleFileRead;

    // Access the 'file' property from the object in the array
    const blob = new Blob([content[0]?.file], { type: content[0]?.file?.type });

    // Read the Blob as data URL
    reader.readAsDataURL(blob);
  };

  const getFileType = () => {
    if (content.length > 0) {
      const type = content[0].file.type.split("/")[0];
      return type;
    }
    return null;
  };

  const renderPreview = () => {
    const fileType = getFileType();

    if (filePreview && fileType === "image") {
      return (
        <img
          src={filePreview}
          alt="Preview"
          style={{ maxWidth: "100%", maxHeight: "200px" }}
        />
      );
    } else if (filePreview && fileType === "video") {
      return (
        <video controls style={{ maxWidth: "100%", maxHeight: "200px" }}>
          <source src={filePreview} type={content[0].file.type} />
          Your browser does not support the video tag.
        </video>
      );
    }

    return null;
  };
  useEffect(() => {
    handleFileSelect();
    setFilePreview("");
  }, [content]);

  const formatFileSize = (bytes) => {
    if (bytes < 1024) {
      return bytes + " Bytes";
    } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + " KB";
    } else {
      return (bytes / (1024 * 1024)).toFixed(2) + " MB";
    }
  };
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel="Attachment Detail Modal"
    >
      <div className="attachmentModal-Container">
        <div className="attachmentModal-header">
          <CloseIcon onClick={closeModal} className="attachmentModal-icon" />
        </div>
        <div className="attachmentModal-attachmentPreview">
          {/* <img
            src={imagePreview}
            alt="preview"
            className="attachmentModal-image"
          /> */}
          {renderPreview() ? (
            renderPreview()
          ) : (
            <TopicIcon style={{ width: 50, height: 50, color: "#8797A1" }} />
          )}
          {!renderPreview() && (
            <p className="attachmentModal-fileName">No Preview Available</p>
          )}
          <p className="attachmentModal-fileName-dark">
            {content[0]?.file?.name}
          </p>
          <p className="attachmentModal-fileName-dark">
            {formatFileSize(content[0]?.file?.size)}
          </p>

          {loadStatus ? (
            <Oval
              visible={true}
              height="50"
              width="50"
              color="#4fa94d"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          ) : (
            <div className="attachmentModal-sendIcon">
              <SendIcon onClick={onClick} />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AttachmentDetailModal;
