import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import "./Settings.css";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LogoutIcon from "@mui/icons-material/Logout";
import InfoIcon from "@mui/icons-material/Info";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/slices/authSlice";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import EditProfileModal from "../EditProfileModal/EditProfileModal";

const Settings = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [editProfile, setEditProfile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
  const [companyName, setCompanyName] = useState(
    user?.data?.company_details?.name
  );
  const [personName, setPersonName] = useState(user?.data?.name);
  const [companyNumber, setCompanyNumber] = useState(
    user?.data?.company_details?.phone
  );
  const [personNumber, setPersonNumber] = useState(user?.data?.phone);

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const openModal = () => {
    onClose();
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openEditModal = () => {
    onClose();
    setIsEditProfileModalOpen(true);
  };
  const closeEditModal = () => {
    setIsEditProfileModalOpen(false);
  };
  const handleCallClick = (contactInfo) => {
    console.log(contactInfo);
    window.location.href = `tel:${contactInfo}`;
  };

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };
  const handlePersonNameChange = (event) => {
    setPersonName(event.target.value);
  };
  const handleCompanyNumberChange = (event) => {
    setCompanyNumber(event.target.value);
  };
  const handlePersonNumberChange = (event) => {
    setPersonNumber(event.target.value);
  };

  return (
    <>
      <Drawer
        anchor="left"
        open={open}
        onClose={onClose}
        className="settings-container"
      >
        <div className="settings-header">
          <ArrowBackIcon style={{ color: "white" }} onClick={onClose} />
          <p className="settings-header-title">Settings</p>
        </div>
        <List className="settings-list">
          <div className="settings-nameContainer">
            <div>
              <div className="settings-companyName-container">
                <ApartmentIcon style={{ color: "#6b7c85" }} />

                <p className="settings-personName">
                  {user?.data?.company_details?.name}
                </p>
              </div>
              <div className="settings-companyNumber-container">
                <LocalPhoneIcon style={{ color: "#6b7c85" }} />

                <p
                  className="settings-personPhone"
                  onClick={() =>
                    handleCallClick(user?.data?.company_details?.phone)
                  }
                >
                  +{user?.data?.company_details?.phone}
                </p>
              </div>
              {/* horizontal line */}
              <div className="settings-horizontalLine"></div>

              <div className="settings-person-container">
                <AccountCircleIcon style={{ color: "#6b7c85" }} />

                <p className="settings-personName">{user?.data?.name}</p>
              </div>

              <div className="settings-personNumber-container">
                <LocalPhoneIcon style={{ color: "#6b7c85" }} />

                <p
                  className="settings-personPhone"
                  onClick={() => handleCallClick(user?.data?.phone)}
                >
                  +{user?.data?.phone}
                </p>
              </div>

              {/* horizontal line */}
              <div className="settings-horizontalLine"></div>
            </div>
            {/* <EditIcon color="action" fontSize="2rem" /> */}
          </div>

          <ListItem button onClick={openEditModal}>
            <DriveFileRenameOutlineIcon style={{ color: "#3B4B54" }} />
            <ListItemText
              primary="Edit Profile"
              className="settings-button-text"
            />
          </ListItem>

          {/* <ListItem button>
          <InfoIcon style={{ color: "#3B4B54" }} />
          <ListItemText primary="About" className="settings-button-text" />
        </ListItem> */}
          <ListItem button onClick={openModal}>
            <LogoutIcon style={{ color: "#3B4B54" }} />
            <ListItemText primary="Logout" className="settings-button-text" />
          </ListItem>
        </List>
      </Drawer>
      <ConfirmationModal
        text={"Are you are sure you want to log out?"}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        onYes={handleLogout}
        yesBtnText={"Yes, Log Out"}
      />
      <EditProfileModal
        isModalOpen={isEditProfileModalOpen}
        closeModal={closeEditModal}
      />
    </>
  );
};

export default Settings;
