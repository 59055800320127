import React from "react";
import Drawer from "@mui/material/Drawer";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ErrorIcon from "@mui/icons-material/Error";
import DoneIcon from "@mui/icons-material/Done";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import "./MessageInfo.css";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import TimeFormatter from "../../utils/TimeFormatter";

const MessageInfo = ({ open, onClose }) => {
  const messageInfo = useSelector((state) => state.chat.selectedMessageInfo);

  const Card = ({ status, time, failedReason }) => {
    const formattedTime = time ? TimeFormatter(time, true) : "-";
    let statusIcon;
    switch (status) {
      case "Read":
        statusIcon = (
          <DoneAllIcon style={{ color: "#5FBEEB", width: 20, height: 20 }} />
        );
        break;
      case "Delivered":
        statusIcon = (
          <DoneAllIcon style={{ color: "#3C3C434D", width: 20, height: 20 }} />
        );
        break;

      case "Sent":
        statusIcon = (
          <DoneIcon style={{ color: "#3C3C434D", width: 20, height: 20 }} />
        );
        break;

      case "Failed":
        statusIcon = (
          <ReportGmailerrorredIcon
            style={{ color: "#DF2C2B", width: 20, height: 20 }}
          />
        );
        break;
      default:
        statusIcon = null;
    }

    return (
      <>
        <div className="messageInfo-status-Read">
          <div className="messageInfo-status-ReadText">
            {statusIcon}
            <p>{status}</p>
          </div>
          <p className="messageInfo-status-readTime">{formattedTime}</p>
          {status === "Failed" && (
            <>
              <div className="messageInfo-status-reasonContainer">
                <FiberManualRecordIcon
                  style={{ color: "#717FA1", width: 10, height: 10 }}
                />
                <p className="messageInfo-status-reason">Reason</p>
              </div>
              <p className="messageInfo-status-failedReason">
                {failedReason ? failedReason : "Unknown"}
              </p>
            </>
          )}
        </div>
        <div className="messageInfo-horizontalLine"></div>
      </>
    );
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <div className="messageInfo-container">
        <div className="messageInfo-header">
          <CloseIcon style={{ color: "#3B4B54" }} onClick={onClose} />
          <p className="messageInfo-header-title">Message info</p>
        </div>

        <div className="messageInfo-status-container">
          <div className="messageInfo-status-innerContainer">
            {messageInfo?.failed_at && (
              <Card
                status={"Failed"}
                time={messageInfo?.failed_at}
                failedReason={messageInfo?.failed_reason}
              />
            )}

            <Card status={"Read"} time={messageInfo?.read_at} />
            <Card status={"Delivered"} time={messageInfo?.delivered_at} />
            <Card status={"Sent"} time={messageInfo?.created_on} />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default MessageInfo;
