const sampleContactList = [
  {
    id: 1,
    personName: "Designers Corner",
    chatContent: {
      sent: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
      received: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
    },
  },
  {
    id: 2,
    personName: "Daniel Kalio",
    chatContent: {
      sent: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
      received: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
    },
  },
  {
    id: 3,
    personName: "Codegaminator 10.0 - UIUX Class",
    chatContent: {
      sent: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
      received: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
    },
  },
  {
    id: 4,
    personName: "BEYOND PEXELS",
    chatContent: {
      sent: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
      received: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
    },
  },
  {
    id: 5,
    personName: "Gideon Nic",
    chatContent: {
      sent: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
      received: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
    },
  },
  {
    id: 6,
    personName: "Rejoice Benson",
    chatContent: {
      sent: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
      received: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
    },
  },
  {
    id: 7,
    personName: "Merry Longs",
    chatContent: {
      sent: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
      received: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
    },
  },
  {
    id: 8,
    personName: "Goodgod UIUX",
    chatContent: {
      sent: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
      received: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
    },
  },
  {
    id: 9,
    personName: "Alex (Tech Sis)",
    chatContent: {
      sent: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
      received: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
    },
  },
  {
    id: 10,
    personName: "Rejoice Benson",
    chatContent: {
      sent: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
      received: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
    },
  },
  {
    id: 11,
    personName: "Goodgod UIUX",
    chatContent: {
      sent: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
      received: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
    },
  },
  {
    id: 12,
    personName: "Alex (Tech Sis)",
    chatContent: {
      sent: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
      received: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
    },
  },
  {
    id: 13,
    personName: "Rejoice Benson",
    chatContent: {
      sent: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
      received: { msg: "Designers Corner", time: "5.20 PM", type: "text" },
    },
  },
];
export default sampleContactList;
