import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseUrl, EndPoint } from "../../constants/constants";

const API_ENDPOINT = BaseUrl + EndPoint.userChat;

const initialState = {
  chatMessages: [],
  status: "idle",
  error: null,
};

export const fetchChatMessages = createAsyncThunk(
  "chatMessage/fetchChatMessages",
  async ({ chatId, token, page }) => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}/${chatId}/message/list`,
        {
          params: { o: page },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

const chatMessageSlice = createSlice({
  name: "chatMessage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatMessages.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChatMessages.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.chatMessages = action.payload.data;
      })
      .addCase(fetchChatMessages.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default chatMessageSlice.reducer;
