import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseUrl, EndPoint } from "../../constants/constants";

const API_ENDPOINT = BaseUrl + EndPoint.login;
const API_USER_PROFILE_ENDPOINT = BaseUrl + EndPoint.userProfile;

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (credentials) => {
    const response = await fetch(API_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    });

    if (!response.ok) {
      throw new Error("Login failed");
    }

    return response.json();
  }
);

export const fetchProfileData = createAsyncThunk(
  "auth/fetchProfileData",
  async (_, { getState }) => {
    const { auth } = getState();

    const response = await fetch(API_USER_PROFILE_ENDPOINT, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${auth.token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user data");
    }

    return response.json();
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: JSON.parse(localStorage.getItem("userData")) || null,
    status: "idle",
    error: null,
    isAuthenticated:
      localStorage.getItem("isAuthenticated") === "true" || false,
    token: localStorage.getItem("authToken") || null,
  },
  reducers: {
    logoutUser: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      localStorage.removeItem("authToken");
      localStorage.removeItem("isAuthenticated");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
        state.token = action.payload.data.auth_key;
        state.isAuthenticated = action.payload.status === 200;
        localStorage.setItem("authToken", state.token);
        localStorage.setItem(
          "isAuthenticated",
          state.isAuthenticated.toString()
        );
        localStorage.setItem("userData", JSON.stringify(state.user));
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.isAuthenticated = false;
      })
      //cases for fetching updated user profile
      .addCase(fetchProfileData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProfileData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
        localStorage.setItem("userData", JSON.stringify(state.user));
      })
      .addCase(fetchProfileData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const { logoutUser } = authSlice.actions;
export default authSlice.reducer;
