import React from "react";
const TextLinkFormatter = ({ text }) => {
  // Regular expression to find URLs in the text
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Split the text into parts based on URLs
  const parts = text.split(urlRegex);

  // Map each part to create a mixture of text and links
  const renderedText = parts.map((part, index) => {
    // Check if the part is a URL
    if (part.match(urlRegex)) {
      return (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    } else {
      return <span key={index}>{part}</span>;
    }
  });

  return <>{renderedText}</>;
};

export default TextLinkFormatter;
