import React, { useState } from "react";
import "./EditProfileModal.css";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";
import { Token } from "@mui/icons-material";
import { editProfile } from "../../redux/slices/userSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const EditProfileModal = ({ isModalOpen, closeModal }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const token = useSelector((state) => state.auth.token);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  const handleEditProfile = (data) => {
    const profileData = { name: data?.name, password: data?.password };
    setLoading(true);
    dispatch(editProfile({ profileData, token }))
      .then((res) => {
        if (res.payload.status === 200) {
          setLoading(false);
          closeModal();
          toast("Details updated Successfully!", {
            autoClose: 1000,
          });
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleYesClick = () => {
    handleSubmit(handleEditProfile)();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel="Edit Profile Modal"
      style={customStyles}
    >
      <div className="editProfile-Container">
        <div className="editProfile-header">
          <p className="editProfile-title">Edit Profile</p>
          <CloseIcon onClick={closeModal} className="editProfile-icon" />
        </div>

        <form
          onSubmit={handleSubmit(handleEditProfile)}
          className="editProfile-form"
        >
          <p className="editProfile-inputTitle">Edit Name</p>
          <div className="editProfile-inputContainer">
            <input
              {...register("name", {
                required: "Name is empty",
              })}
              type="text"
              placeholder="Enter Name"
              className="editProfile-input"
            />
          </div>
          {errors.name && <p style={{ color: "red" }}>{errors.name.message}</p>}
          <p className="editProfile-inputTitle">New Password</p>
          <div className="editProfile-inputContainer">
            <input
              {...register("password")}
              type={showPassword ? "text" : "Password"}
              placeholder="New Password"
              className="editProfile-input"
            />
            {showPassword ? (
              <VisibilityOffIcon
                style={{ color: "#808080" }}
                onClick={handlePasswordVisibility}
              />
            ) : (
              <VisibilityIcon
                style={{ color: "#808080" }}
                onClick={handlePasswordVisibility}
              />
            )}
          </div>

          <p className="editProfile-inputTitle">Confirm Password</p>
          <div className="editProfile-inputContainer">
            <input
              {...register("confirmPassword", {
                validate: (value) => {
                  const newPassword = getValues("password");
                  return newPassword ? value === newPassword : true;
                },

                validate: (value) =>
                  value === getValues("password") || "Passwords do not match",
              })}
              type={showConfirmPassword ? "text" : "Password"}
              placeholder="Confirm Password"
              className="editProfile-input"
            />

            {showConfirmPassword ? (
              <VisibilityOffIcon
                style={{ color: "#808080" }}
                onClick={handleConfirmPasswordVisibility}
              />
            ) : (
              <VisibilityIcon
                style={{ color: "#808080" }}
                onClick={handleConfirmPasswordVisibility}
              />
            )}
          </div>
          {errors.confirmPassword && (
            <p style={{ color: "red" }}>{errors.confirmPassword.message}</p>
          )}
        </form>
        {loading ? (
          <div className="editProfile-spinner">
            <Oval
              visible={true}
              height="50"
              width="50"
              color="#4fa94d"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        ) : (
          <div className="editProfile-btn-container">
            <p className="editProfile-btn-cancel" onClick={closeModal}>
              Cancel
            </p>
            <p className="editProfile-btn-yes" onClick={handleYesClick}>
              Yes, Save it?
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default EditProfileModal;
