const sampleChatContent = [
  {
    id: 1,
    type: "text",
    sender: "user1",
    content: "Hello!",
    timestamp: 1641973865000,
  },
  {
    id: 2,
    type: "text",
    sender: "user2",
    content: "Hi there!",
    timestamp: 1641973875000,
  },
  {
    id: 3,
    type: "audio",
    sender: "user1",
    content: "audio-file-url.mp3",
    timestamp: 1641973885000,
  },
  {
    id: 4,
    type: "video",
    sender: "user2",
    content: "video-file-url.mp4",
    timestamp: 1641973895000,
  },
  {
    id: 5,
    type: "image",
    sender: "user1",
    content: "image-file-url.jpg",
    timestamp: 1641973905000,
  },
  {
    id: 6,
    type: "text",
    sender: "user2",
    content: "How are you?",
    timestamp: 1641973915000,
  },
];
export default sampleChatContent;
