import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = (props) => {
  const { Component } = props;
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.auth.token);

  useEffect(() => {
    let authToken = localStorage.getItem("authToken");
    if (!authToken) {
      navigate("/login");
    }
  }, [authToken, navigate]);

  return <Component />;
};

export default ProtectedRoute;
