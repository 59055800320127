const FileDownloader = (url, fileName) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const blobURL = window.URL.createObjectURL(new Blob([blob]));
      const fileNameBlob = url.split("/").pop();
      const aTag = document.createElement("a");
      aTag.href = blobURL;
      aTag.setAttribute("download", fileName || fileNameBlob);
      document.body.appendChild(aTag);
      aTag.click();
      aTag.remove();
    });
};

export default FileDownloader;
