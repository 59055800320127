import { configureStore } from "@reduxjs/toolkit";
import sidebarReducer from "./slices/sidebarSlice";
import mobileReducer from "./slices/mobileSlice";
import authReducer from "./slices/authSlice";
import chatReducer from "./slices/chatSlice";
import chatMessageReducer from "./slices/chatMessageSlice";
import messageReducer from "./slices/messageSlice";
import userReducer from "./slices/userSlice";

const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    mobile: mobileReducer,
    auth: authReducer,
    chat: chatReducer,
    chatMessage: chatMessageReducer,
    message: messageReducer,
    user: userReducer,
  },
});

export default store;
