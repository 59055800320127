import React, { useEffect, useState } from "react";
import "./ImageModal.css";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileDownloader from "../../utils/FileDownloader";
import { useSelector } from "react-redux";

const ImageModal = ({ isModalOpen, closeModal }) => {
  const imagePreview = useSelector((state) => state.chat.selectedImagePreview);

  useEffect(() => {
    Modal.setAppElement("#root");
  }, []);

  const handleDownload = (url) => {
    FileDownloader(url);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel="Image Modal"
    >
      <div className="imageModal-Container">
        <div className="imageModal-header">
          <FileDownloadIcon
            className="imageModal-icon"
            onClick={() => handleDownload(imagePreview)}
          />
          <CloseIcon onClick={closeModal} className="imageModal-icon" />
        </div>
        <div className="imageModal-imagePreview">
          <img
            src={imagePreview}
            alt="image-chat"
            className="imageModal-image"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ImageModal;
