import React, { useEffect } from "react";
import "./Home.css";
import Sidebar from "../Sidebar/Sidebar";
import ChatBox from "../ChatBox/ChatBox";
import RightSideBar from "../RightSideBar/RightSideBar";
import { useDispatch, useSelector } from "react-redux";
import { setIsMobile } from "../../redux/slices/mobileSlice";

const Home = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.mobile.isMobile);
  const isSidebarVisible = useSelector(
    (state) => state.sidebar.isSidebarVisible
  );

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 768;
      dispatch(setIsMobile(isMobile));
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="home-container">
      {isMobile ? isSidebarVisible && <Sidebar /> : <Sidebar />}
      <ChatBox />
      <RightSideBar />
    </div>
  );
};

export default Home;
